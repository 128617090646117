import dayjs from 'dayjs'
import { storyblokConfig } from '@/storyblok/storyblok-config'

const STORYBLOK_FORMAT = storyblokConfig.dateFormat
const OUTPUT_FORMAT = 'MMMM D, YYYY' // 'DD.MM.YYYY'

/**
 * Get date
 */

export const getDate = (string) => {
  return dayjs(string, STORYBLOK_FORMAT).format(OUTPUT_FORMAT)
}
