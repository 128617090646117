import { getMedia } from '@/storyblok/helpers/getMedia'
import { linkResolver } from '@/storyblok/helpers/linkResolver'
import { getTagByUid } from '@/storyblok/helpers/getTagByUid'
import { getDate } from '@/storyblok/helpers/getDate'

/**
 * Get Article data
 *
 * Convert Storyblok data to Thumb format
 */

export const getArticle = (node, { allTags = [], hideDates = false }) => {
  // Parse content if necessary
  if (node && typeof node.content === 'string') {
    node.content = JSON.parse(node.content)
  }

  const categories = []
  node.content?.categories?.forEach((category) => {
    const match = getTagByUid(category, allTags)
    if (match) categories.push(match)
  })

  const tags = [...categories]

  return {
    uid: node.uuid,
    url: linkResolver(node.full_slug),
    title: node.name,
    date: getDate(node.first_published_at),
    hideDate: hideDates || node.content?.hide_publish_date,
    thumbnail: !!node.content?.thumbnail?.length
      ? getMedia(node.content?.thumbnail[0], { maxWidth: 600 })
      : null,
    categories,
    tags,
  }
}
