import { useStaticQuery, graphql } from 'gatsby'

/**
 * Pull in Storyblok tags list
 */

export const useArticleTags = () => {
  const data = useStaticQuery(
    graphql`
      query {
        tags: allStoryblokEntry(
          filter: { field_component: { eq: "article_tag" } }
        ) {
          edges {
            node {
              slug
              name
              full_slug
              uuid
              content
            }
          }
        }
      }
    `
  )

  return data.tags?.edges.map(({ node }) => {
    if (node && typeof node.content === 'string') {
      node.content = JSON.parse(node.content)
    }

    return {
      uid: node.uuid,
      slug: node.slug,
      title: node.name,
      singularTitle: node.content?.singular_name || node.name,
      showOnThumbs: node.content?.show_on_thumbnails,
    }
  })
}
