import React from 'react'
import { graphql } from 'gatsby'
import { useStoryblok } from '@/storyblok/hooks/useStoryblok'
import Articles from '@/storyblok/templates/Articles.storyblok'

/**
 * Articles blueprint
 */

const ArticlesBlueprint = ({ data, location, pageContext }) => {
  const story = useStoryblok(data.story, location)
  const articles = data.articles?.edges

  return (
    <Articles
      story={story}
      articles={articles}
      pageContext={pageContext}
      location={location}
    />
  )
}

export default ArticlesBlueprint

export const query = graphql`
  query StoryblokArticles($id: String!) {
    story: storyblokEntry(id: { eq: $id }) {
      id
      name
      content
      slug
      uuid
      full_slug
      field_component
      first_published_at
    }
    articles: allStoryblokEntry(
      filter: { field_component: { eq: "article" } }
      sort: { order: DESC, fields: first_published_at }
    ) {
      edges {
        node {
          name
          slug
          full_slug
          uuid
          content
          sort_by_date
          first_published_at
        }
      }
    }
  }
`
